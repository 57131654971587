<template>
    <v-card
        class="mx-auto cursor-pointer pa-2 card-border overflow-hidden"
        max-width="274"
        height="224"
        elevation="0"
        @mouseenter="overlay = true"
        @mouseleave="overlay = false"
        @click="dialog = true"
    >
        <v-img height="200"
            :src="activityData.type === 'img' ? activityData.url : activityData.previewImg">
        </v-img>
        <v-overlay absolute :value="overlay">
            <v-icon large>mdi-eye</v-icon>
        </v-overlay>
        <ActivityModal
            :dialog="dialog"
            :link="activityData.url"
            :name="activityData.name"
            :type="activityData.type"
            @close-modal="dialog = false"
        ></ActivityModal>
    </v-card>
</template>

<script>
import ActivityModal from '../modals/ActivityModal'

export default {
    name: 'ActivityCard',
    components: { ActivityModal },
    props: { activityData: { type: Object, default: () => null } },
    data: () => ({
        absolute: true,
        overlay: false,
        dialog: false,
    }),
}
</script>

<style scoped>
.card-border {
    border: 2px solid #c8d0cb;
}
</style>
