<template>
    <div>
        <v-overlay v-model="dialog" color="primary" style="z-index: 6"></v-overlay
        ><v-dialog
            v-model="dialog"
            content-class="d-flex elevation-0 position-relative"
            transition="dialog-bottom-transition"
            persistent
            width="900"
            elevation="0"
            :fullscreen="preview"
            hide-overlay
        >
            <v-btn
                small
                icon
                color="primary"
                elevation="2"
                class="primary lighten-2 white--text position-absolute btn-corner"
                @click="exitCard"
            >
                <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
            <v-btn
                color="primary"
                small
                icon
                v-if="preview"
                @click="preview = false"
                class="primary lighten-2 white--text position-absolute btn-corner-left"
                ><v-icon class="white--text">mdi-arrow-left</v-icon></v-btn
            >
            <v-card
                elevation="0"
                color="white"
                class="position-relative cursor-pointer white overflow-hidden"
                v-if="dialog"
            >
                <v-container>
                    <v-row class="px-5" v-if="!preview">
                        <v-col
                            cols="12"
                            @mouseenter="overlay = true"
                            @mouseleave="overlay = false"
                            @click="preview = true"
                            class="position-relative"
                        >
                            <v-img
                                :src="link"
                                width="100%"
                                max-height="350"
                                class="border-radius-1"
                                v-if="type === 'img'"
                            ></v-img>
                            <v-card width="100%" height="350" v-else class="overflow-hidden">
                                <pdf :src="link"></pdf>
                            </v-card>
                            <v-overlay absolute :value="overlay">
                                <v-icon large>mdi-eye</v-icon>
                            </v-overlay>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                            <v-card-title class="pa-0 grey--text">{{ name }}</v-card-title>
                        </v-col>
                        <v-card-actions class="d-flex justify-center align-center w-100 mt-5">
                            <v-btn
                                color="secondary"
                                outlined
                                class="text-capitalize font-weight-light"
                                @click="preview = true"
                                ><v-icon class="mr-2">mdi-eye</v-icon>
                                {{ $t('resources.preview') }}</v-btn
                            >
                        </v-card-actions>
                    </v-row>
                    <v-row v-if="preview">
                        <v-col cols="12" class="d-flex justify-center align-center">
                            <img
                                :src="link"
                                height="600"
                                alt="image"
                                class="border-radius-1"
                                v-if="type === 'img'"
                            />
                            <object
                                width="75%"
                                height="600px"
                                v-else
                                :data="link"
                                type="application/pdf"
                            ></object>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
    name: 'ActivityModal',
    components: {
        pdf,
    },
    props: {
        link: { type: String, default: '' },
        name: { type: String, default: 'Untitle' },
        type: { type: String, default: 'untyped' },
        dialog: { type: Boolean, default: false },
    },
    data: () => ({
        preview: false,
        overlay: false,
    }),
    methods: {
        exitCard() {
            this.preview = false
            this.$emit('close-modal')
        },
    },
}
</script>

<style scoped>
.btn-corner {
    z-index: 1;
    top: 1vh;
    right: 1vh;
}
.btn-corner-left {
    z-index: 1;
    top: 1vh;
    left: 1vh;
}
</style>
