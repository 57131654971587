<template>
    <v-card flat elevation="0" class="pa-6">
        <v-container class="pa-0">
            <v-row class="pa-0">
                <v-col cols="2">
                    <div class="d-flex justify-start align-start flex-column w-100">
                        <span class="primary--text font-size-18">{{
                            $t('resources.activity_type')
                        }}</span>
                        <v-radio-group v-model="typeSelect" class="pa-0 ma-0 mt-2 w-100">
                            <v-radio
                                v-for="(category, index) of categories"
                                :key="index"
                                :label="`${category.name}`"
                                :value="category.value"
                                class="radio-content primary--text py-0 w-100"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </v-col>
                <v-col cols="10">
                    <v-card class="card-border pa-3 overflow-y-auto" elevation="0" height="750">
                        <v-row align="start" justify="start">
                            <v-col
                                v-for="(activity, index) of activitiesList"
                                :key="index"
                                cols="4"
                            >
                                <ActivityCard :activity-data="activity"></ActivityCard>
                            </v-col>
                        </v-row>
                        <v-row></v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>

import ActivityCard from './core/ActivityCard'
import activitiesService from '../../services/activities'

export default {
    name: 'Activities',
    components: { ActivityCard },
    computed: {
        categories() {
            return [
                { value: 0, name: this.$t('resources.all') },
                { value: 1, name: this.$t('resources.from_six_years') },
                { value: 2, name: this.$t('resources.from_nine_years') },
                { value: 3, name: this.$t('resources.from_twelve_years') },
                { value: 4, name: this.$t('resources.from_fourteen_years') },
                { value: 5, name: this.$t('resources.from_sixteen_years') },
            ]
        },
        activitiesList() {
            if (this.typeSelect !== 0) return this.activities.filter(({ typeValue }) => typeValue === this.typeSelect)
            return this.activities
        },
    },
    data: () => ({
        typeSelect: 0,
        activities: [],
    }),
    mounted() {
        activitiesService.getActivities().then((response) => {
            this.activities = response
        })
    }
}
</script>

<style scoped>
.card-border {
    border: 2px solid #c8d0cb;
}
</style>
